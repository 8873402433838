import React from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { TextVariant } from '@naf/text';
import { OrderPaymentStatus } from '@dtp/membership-service-types';
import { CardSize, CardNavigation } from '@naf/cards';
import type { SanityBlock } from '@sanity/client';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import Loader from '../../components/loader';
import { useOrder } from '../../hooks/useOrder';
import S from './Styles';
import { formattedPrice } from '../../lib/formattedPrice';
import { waitingCodes } from '../../hooks/useRunningUpdates';
import BlockContent from '../../components/block-content/BlockContent';
import { isInternalLink } from '../../lib/isInternalLink';
import useSelector from '../../redux/typedHooks';
import { NavCardLink } from '../../components/styled-link/StyledLink';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import StepByStepList from '../../components/StepByStepList/StepByStepList';

type StepByStep = {
  title: string;
  ingress: string;
  list: {
    title: string;
    body: SanityBlock[];
  }[];
};

interface Props {
  match: { params: { orderId: string } };
}

export const Welcome = ({
  match: {
    params: { orderId },
  },
}: Props) => {
  const order = useOrder(orderId);
  const {
    summary: { firstName, customerNumber, orderSummaries, email, grandTotal, isNewCustomer },
    productPaymentOutcome,
  } = order || { summary: {}, productPaymentOutcome: {} };

  const { paymentReceipt } = productPaymentOutcome || {};

  const baseUrl = useSelector((state) => state.application.baseUrl);

  const linkEntries = paymentReceipt?.mainEntries || [];
  const fullLinkRows = Math.floor(linkEntries.length / 2);
  const partiallyFullLinkRows = linkEntries.length % 2;
  const numberOfLinkRows = fullLinkRows + partiallyFullLinkRows;
  const linkRowArray = [...Array(numberOfLinkRows).keys()];

  const stepByStep = (paymentReceipt as any)?.stepByStep as StepByStep;
  const windowPath = useWindowLocation();

  return (
    <LayoutWithMainContent
      title="Velkommen"
      description="Du har blitt registrert"
      url={windowPath}
      imgUrl={DEFAULT_IMG_URL}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <Grid>
        {!order ||
          (waitingCodes.includes(order.status) && order.paymentOrderStatus === OrderPaymentStatus.PAID && (
            <>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.Header variant={TextVariant.Header1}>Velkommen som medlem</S.Header>
              </GridCol>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.LoaderWrapper>
                  <Loader />
                </S.LoaderWrapper>
                <S.AnimatedText variant={TextVariant.Header3}>
                  Takk for din betaling. Vi forbereder dine medlemssider.
                </S.AnimatedText>
              </GridCol>
            </>
          ))}
        {order && !waitingCodes.includes(order.status) && order.paymentOrderStatus === OrderPaymentStatus.PAID && (
          <>
            <GridCol s="12" m="12" l="12" xl="12">
              <S.Header variant={TextVariant.Header1}>Velkommen som medlem i NAF, {firstName}!</S.Header>
            </GridCol>
            <GridCol s="12" m="12" l="6" xl="6">
              <S.Wrapper>
                <S.MemberNumber variant={TextVariant.Header3}>Ditt medlemsnummer er: {customerNumber}</S.MemberNumber>
                {isNewCustomer && (
                  <S.StyledP>Vi har sendt deg en e-post til {email}, som bekrefter din bestilling.</S.StyledP>
                )}
                <BlockContent value={paymentReceipt?.body} />
                {stepByStep && (
                  <StepByStepList
                    title={stepByStep?.title}
                    ingress={stepByStep?.ingress}
                    list={stepByStep.list}
                    variant="secondary"
                  />
                )}
              </S.Wrapper>
            </GridCol>
            <GridCol s="12" m="12" l="12" xl="4">
              <S.SummaryWrapper>
                <S.Summary>
                  <S.SummaryHeading variant={TextVariant.Header3}>Ditt kjøp</S.SummaryHeading>
                  {orderSummaries &&
                    orderSummaries.map((orderLine) => (
                      <div key={orderLine.productNumber}>
                        <S.LineWrapper>
                          <S.LineText variant={TextVariant.ArticleTextHeader}>{orderLine.productName}</S.LineText>
                          <S.Price variant={TextVariant.ArticleTextHeader}>
                            {formattedPrice(orderLine.productPrice)} kr
                          </S.Price>
                        </S.LineWrapper>
                        <S.LineText variant={TextVariant.ArticleText}>Antall personer: {orderLine.number}</S.LineText>
                      </div>
                    ))}
                  <S.LineWrapper $withTopBorder>
                    <S.LineText variant={TextVariant.ArticleTextHeader}>Totalt:</S.LineText>
                    <S.TotalPrice variant={TextVariant.ArticleTextHeader}>
                      {grandTotal && formattedPrice(grandTotal)} kr
                    </S.TotalPrice>
                  </S.LineWrapper>
                </S.Summary>
              </S.SummaryWrapper>
            </GridCol>
          </>
        )}
        {order &&
          (order.paymentOrderStatus === OrderPaymentStatus.CANCELED ||
            order.paymentOrderStatus === OrderPaymentStatus.NONE) && (
            <>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.Header variant={TextVariant.Header1}>Du avbrøt betalingen!</S.Header>
              </GridCol>
              <GridCol s="12" m="12" l="6" xl="6">
                <S.Wrapper>
                  <S.TextWrapper>
                    <BlockContent value={order.productPaymentOutcome?.paymentCanceled?.body} />
                  </S.TextWrapper>
                </S.Wrapper>
              </GridCol>
            </>
          )}
        {order && order.paymentOrderStatus === OrderPaymentStatus.PAID && !waitingCodes.includes(order.status) && (
          <S.LinkWrapper>
            {linkRowArray.map((rowNumber) => (
              <GridRow key={`row-number-${rowNumber}`}>
                {[...Array(2).keys()].map((i) => {
                  const itemIndex = rowNumber * 2 + i;
                  const item = linkEntries[itemIndex];
                  const { isInternal, relativeLink } = isInternalLink(baseUrl, item?.link?.href);

                  // If last item of row is not populated
                  if (typeof item === 'undefined') {
                    return null;
                  }

                  // If item is internal link
                  if (isInternal) {
                    return (
                      <GridCol s="12" m="12" l="6" xl="6" key={`row-number-${rowNumber}-item${i}`}>
                        <NavCardLink
                          to={relativeLink}
                          target={item.link?.blank ? '_blank' : '_self'}
                          key={`row-number-${rowNumber}-item${i}`}
                        >
                          <CardNavigation title={item.title || ''} size={CardSize.Large} isInternalLink height="100%" />
                        </NavCardLink>
                      </GridCol>
                    );
                  }

                  // External link
                  return (
                    <GridCol s="12" m="12" l="6" xl="6" key={`row-number-${rowNumber}-item${i}`}>
                      <CardNavigation
                        key={`row-number-${rowNumber}-item${i}`}
                        href={item.link?.href || undefined}
                        target={item.link?.blank ? '_blank' : '_self'}
                        title={item.title || ''}
                        height="100%"
                        size={CardSize.Large}
                      >
                        {item.description}
                      </CardNavigation>
                    </GridCol>
                  );
                })}
              </GridRow>
            ))}
          </S.LinkWrapper>
        )}
      </Grid>
    </LayoutWithMainContent>
  );
};
